/*
** Nav Dropdowns
*/
$('.nav .dropdown ul ul > li').mouseenter(function(){
	if ( $(this).find('> ul').length ) {
		$('.header').addClass('header--nav')
	}
})

$('.nav .dropdown ul ul').mouseleave(function(){
	$('.header').removeClass('header--nav')
})

$('.nav .dropdown__link').on('click', function(e){
	e.preventDefault()

	$(this).closest('.is-open').removeClass('is-open')
})

$('.nav a').on('click', function(e){
	var $parent    = $(this).parent();
	var $siblingUl = $parent.find('> ul, > .dropdown');

	if ( $(window).width() < 1024 ) {
		if ( $siblingUl.length && !$parent.hasClass('is-open') && !$(this).hasClass('dropdown__link') ){
			$parent.addClass('is-open').siblings().removeClass('is-open').find('.is-open').removeClass('is-open');

			e.preventDefault();
		} 
	}
})