/* Google Recaptcha V3 Scripts */
const siteKey = window.reCaptchaSiteKey;
const reCaptchaElements = document.querySelectorAll('[data-captchaguid]');
const reCaptchaFormComponentGuids = [];
reCaptchaElements.forEach(element => {
    const guid = element.getAttribute('data-captchaguid');
    reCaptchaFormComponentGuids.push(guid);
});

function initReCaptchaV3() {
    main();
}

function onClick(e) {
    const formElement = document.getElementById(formId);

    if (!formElement.classList.contains("search-form")) {
        e.preventDefault();
        e.stopPropagation();

        const formGuid = getFormGuid(e.submitter.form.id);

        if (document.getElementById(`${formGuid}-captchaToken`).value !== '') {
            return false;
        }

        grecaptcha.ready(() => {
            grecaptcha
                .execute(siteKey, { action: 'submit' })
                .then(token => {
                    document.getElementById(`${formGuid}-captchaToken`).value = token;

                    // trigger original click event
                    if (e.submitter !== undefined) {
                        addFormOnSubmitFunction(e.submitter.form.id);
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        });
    }    
}

function getFormGuid(formId) {
    const formElement = document.getElementById(formId);
    const formGuid = formElement
        .querySelectorAll('[data-captchaguid]')[0]
        .getAttribute('data-captchaguid');

    return formGuid;
}

function getFormOnSubmitFunction() {
    reCaptchaFormComponentGuids.forEach(guid => {
        const captchaElement = document.getElementById(`${guid}-captchaToken`);
        const parentForm = captchaElement.closest('form');
        const onSubmitFunction = parentForm.getAttribute('onsubmit');

        window[`${guid}-submitFunction`] = onSubmitFunction;
    });
}

function removeFormOnSubmitFunction() {
    reCaptchaFormComponentGuids.forEach(guid => {
        const captchaElement = document.getElementById(`${guid}-captchaToken`);
        const parentForm = captchaElement.closest('form');

        parentForm.removeAttribute('onsubmit');
    });
}

function addFormOnSubmitFunction(eventSubmitterFormId) {
    const formElement = document.getElementById(eventSubmitterFormId);
    const formGuid = getFormGuid(eventSubmitterFormId);

    const submitFunction = window[`${formGuid}-submitFunction`];
    formElement.setAttribute('onsubmit', submitFunction);

    const submitButton = formElement.querySelectorAll("input[type='submit']")[0];
    submitButton.click();
}

function main() {
    window.addEventListener('load', () => {
        getFormOnSubmitFunction();
        removeFormOnSubmitFunction();
    });

    document.addEventListener('submit', onClick);
}

initReCaptchaV3();