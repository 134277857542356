var winST = $(window).scrollTop()

/*
** Animate Elements
*/
function animateBaseElements() {
	setTimeout(function() {
		$('.wrapper').addClass('animate-elements')
	}, 500);
}

/*
** Animate Elements on Scroll
*/
function animate(winST) {
	$('.animate').each(function(){
		var $this = $(this);

		if (winST + ($(window).outerHeight() / 1.2) > $this.offset().top) {
			$this.addClass('is-animated');
		} else if (winST + ($(window).outerHeight() / 2) < $this.offset().top) {
			$this.removeClass('is-animated');
		}		
	});
}

/*
** Window Events
*/
$(window).on('load', function(){
	animateBaseElements()
}).on('load scroll', function(){
	winST = $(window).scrollTop()
	
	animate( winST )	
})