/*
** Accordions
*/
$('.accordion').on('click', '.accordion__head', function() {

	$(this)
		.closest('.accordion')
		.toggleClass('is-active')
		.find('.accordion__body')
		.slideToggle()
		.closest('.accordion')
		.siblings()
		.removeClass('is-active')
		.find('.accordion__body')
		.slideUp()
})