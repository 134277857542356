/*
** Search
*/
$('.search .search__field').on('input', function(){
	var $this = $(this)

	if ( $this.val().length ) {
		$this.closest('.search').addClass('is-typed')
	} else {
		$this.closest('.search').removeClass('is-typed')
	}
})