/*
** Hide Header on on scroll down
*/
var didScroll;
var lastScrollTop = 0;
var delta         = 5;
var headerHeight  = $('.header').outerHeight();

function hasScrolled() {
	var st = $(window).scrollTop();
	
	if(Math.abs(lastScrollTop - st) <= delta)
		return;

	if (st > lastScrollTop && st > headerHeight){
		// Scroll Down
		$('.header').removeClass('down').addClass('up');
	} else {
		// Scroll Up
		if(st + $(window).height() < $(document).height()) {
			$('.header').removeClass('up').addClass('down');
		}
	}
	
	lastScrollTop = st;
}

setInterval(function() {
	if (didScroll) {
		hasScrolled();
		didScroll = false;
	}
}, 250);

$(window).on('scroll', function(){
	didScroll = true;
});

