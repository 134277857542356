/*
** Click Handlers
*/

//Button Burger
$('.btn-burger').on('click', function(e){
	e.preventDefault()

	$('.header').toggleClass('is-active')
	$('.header').removeClass('header--search')
	$('.nav .is-open').removeClass('is-open')
})

//Search Button
$('.search__btn-alt').on('click', function(e){
	e.preventDefault()

	var $this = $(this)

	$('.header').toggleClass('header--search')
	$('.header').removeClass('is-active')
	$('.nav .is-open').removeClass('is-open')
	$this.closest('.search').removeClass('is-typed').find('.search__field').val('')
})

//Button Up
$('.btn-up').on('click', function(e){
	e.preventDefault()

	$('html, body').animate({
		scrollTop: 0
	})
})

//Timeline Item
$('.timeline__item').on('mouseenter', function(){
	$(this).addClass('is-active').siblings().removeClass('is-active')
})